import React, { Fragment, useState, useEffect } from 'react';
import JobFilter from '../../jobSearch/job-filter';
import Breadcrumb from '../../common/breadcrumb';
import { Navbar, Form, Nav, FormControl, NavDropdown } from 'react-bootstrap';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Collapse } from 'reactstrap';
import { TabContent, TabPane, NavItem, NavLink } from 'reactstrap';
import nupielogo from '../../../pages/nupie.png';
import Login from '../../../pages/loginStudent';
import Signup from '../../../pages/signup';
import { loginStudent } from "../../../actions/UserContext";
import { getAllCourses, getAllPayments, getCategory } from '../../../actions/asyncActions';
import UserLogo from '../../../assets/images/user/user.png';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem ,Dropdown} from 'reactstrap';

const JobList = (props) => {
    const location = props.location
    const catId = location.state ? location.state.id : 1;
    const user = JSON.parse(localStorage.getItem("pm_users"));
    console.log("user", user);

    const [modal2, setModal2] = useState();

    var [loginValue, setLoginValue] = useState("");
    var [passwordValue, setPasswordValue] = useState("");


   // const [courseId, setCourseId] = useState();
    const [courseName, setCourseName] = useState();
    const [coursePrice, setCoursePrice] = useState();

    const [payments, setPayment] = useState([]);
    const [isFilter, setIsFilter] = useState(true);

    useEffect( () => {
     getAllPayments()
     .then(resp => {
      setPayment(resp.data);
     })
    }, []);

    const [categories, setCategory] = useState([]);  

      useEffect( () => {
          getCategory()
          .then(resp => setCategory(resp.data))
        }, []); 

    const toggle2 = (n) => {
        setCourseId(n.id);
        setCourseName(n.course_name);
        setCoursePrice(n.price);
        setModal2(!modal2)
    }

    const handleCheckout = (n) => {
        setCourseId(n.id);
        setCourseName(n.course_name);
        setCoursePrice(n.price);
        console.log("course_name", n.course_name);
        props.history.push(`${process.env.PUBLIC_URL}/courses/checkout`, { courseId: n.id, courseName:n.course_name, coursePrice: n.price });
    }

    const [activeTab8, setActiveTab8] = useState('1');

    const [courses, setCourses] = useState([]);  

    useEffect( () => {
        getAllCourses()
        .then(resp => setCourses(resp.data))
      }, []);

    const [cat_Id, setCatId] = useState();
    const clickFilter = (s) => {
        setCatId(s.id);
    }

    const filterCourses = cat_Id ? courses.filter(s => s.cat_id ===cat_Id) : courses;

    // const courseList = courses.filter(s=> s.cat_id === catId);

    const [changeStatus, setStatus] = useState(false);
    const [courseId, setCourseId] = useState();

    const clickStatus = (n) => {
        setCourseId(n.id);
        setStatus(true);
    }


    const clickCourseDetail = (n) => {
        props.history.push(`${process.env.PUBLIC_URL}/courses/course_detail`, { id: n.id });
    }

    const clickApply = () => {
        props.history.push(`${process.env.PUBLIC_URL}/pages/login`);
    }

    const clickApplySignup = () => {
        props.history.push(`${process.env.PUBLIC_URL}/pages/signup`);
    }

    const clickApplyLogo = () => {
        props.history.push(`${process.env.PUBLIC_URL}/pages/home`);
    }

    const clickMyCourses = () => {
      props.history.push(`${process.env.PUBLIC_URL}/courses/mycourses`);
    }

    const clickAllCourses = () => {
      props.history.push(`${process.env.PUBLIC_URL}/courses/all_course_list`);
    }


    const logOut =(history) => {
      console.log("history",history)
      localStorage.clear("id_token");
      //localStorage.removeItem("id_token");
      props.history.push(`${process.env.PUBLIC_URL}/pages/home`);
    }

    const handleSubmit = (course_id) => {
        console.log("course_id", course_id);
        props.history.push(`${process.env.PUBLIC_URL}/courses/course_models`, { id: course_id });
    }

    return (
        <Fragment>
        <div className="page-wrapper">
            <div className="container-fluid p-0 m-0">

            <Navbar collapseOnSelect bg="light" variant="light" expand="lg" fixed="top">
                <Navbar.Brand onClick={() => clickApplyLogo()}>
                  <img
                    src={nupielogo}
                    width="30"
                    height="30"
                    style={{cursor: "pointer"}}
                    className="d-inline-block align-top"
                  />{' '} LMS Nu-pie
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                          <Nav.Link onClick={() => clickAllCourses()}>All Courses</Nav.Link>                          
                          {user ?
                            <Nav.Link onClick={() => clickMyCourses()}>My Courses</Nav.Link>
                            : null
                          }
                         {/* <NavDropdown title="Our Blog" id="collasible-nav-dropdown">
                            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                          </NavDropdown> */}
                        </Nav>

                    { user ?
                        <Nav>                        
                           {/* <button className="btn btn-square btn-outline-primary btn-sm" type="button" 
                                onClick={() => logOut(props.history)} >
                                LogOut ({user.firstname})
                              </button>  */}

                            <UncontrolledDropdown setActiveFromChild>
                                <DropdownToggle tag="a" caret>
                                    <img
                                      className="img-30 rounded-circle"
                                      src={UserLogo}
                                      style={{cursor: "pointer"}}
                                      alt="#"
                                    />
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem active>{user.firstname}</DropdownItem>
                                    <DropdownItem onClick={() => clickMyCourses()}>
                                      My Courses
                                    </DropdownItem>
                                    <DropdownItem onClick={() => logOut(props.history)}>
                                      <i className= "icon-power-off"></i> LogOut
                                    </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>                      
                        </Nav>
                        :
                        <Nav>
                          <button className="btn btn-square btn-outline-primary btn-sm" type="button" onClick={() => clickApply()}>
                            Login
                          </button>
                          <button className="btn btn-square btn-warning btn-sm" type="button" onClick={() => clickApplySignup()}>
                            Enroll Today
                          </button>
                        </Nav>
                      }     

                    </Navbar.Collapse>
            </Navbar>

           {/* <Breadcrumb title="List View" parent="Job Search" /> */}
            <div className="container-fluid" style={{paddingTop:'20px'}}>
                <div className="card">
                    <div className="card-header">
                        <h5 align='center'>Course List</h5>
                    </div>
                </div>

                <div className="row">

                <div className="col-xl-3 xl-40">
                <div className="default-according style-1 faq-accordion job-accordion" id="accordionoc">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link pl-0" data-toggle="collapse" onClick={() => setIsFilter(!isFilter)}
                                            data-target="#collapseicon" aria-expanded={isFilter} aria-controls="collapseicon">Filter</button>
                                    </h5>
                                </div>
                                <Collapse isOpen={isFilter}>
                                    <div className="card-body filter-cards-view animate-chk">

                                        <div className="list-group">
                                            {categories.map((s, i) => {

                                                if(cat_Id === s.id) {
                                                    return (                              
                                                        <a className="list-group-item list-group-item-action" style={{cursor: "pointer", color:"blue"}} onClick={ () => clickApply(s)}>
                                                          {s.name}
                                                        </a>                                               
                                                    )
                                                } else {
                                                    return (                                                                                     
                                                      <a className="list-group-item list-group-item-action" style={{cursor: "pointer"}} onClick={ () => clickFilter(s)}>
                                                        {s.name}
                                                      </a>                                               
                                                      )
                                                }                                                  
                                                
                                              })}
                                        </div>                                        
                                        
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                </div>
                </div>

                    {/* <JobFilter /> */}
                    <div className="col-xl-9 xl-60" >
                        
                        {filterCourses.map((n, i) => {
                            const finduserCourse = user ? payments.find(s => s.user_id === user.id && s.course_id===n.id) : null;
                            console.log("finduserCourse", finduserCourse);
                            
                            return (
                                <div className={`card ${n.price ? '' : 'ribbon-vertical-left-wrapper'}`} key={i} onClick={ () => clickStatus(n)}>
                                    <div className="job-search">
                                        <div className="card-body">
                                            <div className="media">
                                                <img className="img-40 img-fluid m-r-20" src={require('../../../assets/images/job-search/1.jpg')} alt="" />
                                                <div className="media-body">
                                                    <h6 className="f-w-600"><a href="#javascript">{n.course_name}</a>
                                                        {(n.badgeType === 'primary' ? 
                                                            <div>                                                                
                                                                { finduserCourse ?
                                                                    <span className="badge badge-secondary pull-right text-light" style={{cursor: "pointer"}}
                                                                        onClick={() => user ?
                                                                                handleSubmit(n.id)
                                                                                :
                                                                                toggle2(n)
                                                                            }
                                                                        >
                                                                        Start Learning
                                                                    </span> :
                                                                    <span className="badge badge-primary pull-right">Price: ₹{n.price}</span> 
                                                                }                                                                 
                                                                    
                                                                { changeStatus === true && courseId === n.id  ?

                                                                   <div>
                                                                    { finduserCourse ?
                                                                        null :
                                                                        <span className="badge badge-secondary pull-right text-light" style={{cursor: "pointer"}}
                                                                            onClick={() => user ?
                                                                                    handleCheckout(n)
                                                                                    :
                                                                                    toggle2(n)
                                                                                }
                                                                            >
                                                                            Start Course
                                                                        </span>
                                                                    }
                                                                    </div>

                                                                    : null 
                                                                }
                                                               {/*
                                                                { changeStatus === true && courseId === n.id  ?
                                                                    <span className="badge badge-warning pull-right text-light" onClick={ () => clickCourseDetail(n)}>
                                                                        Know More
                                                                    </span> 
                                                                    : null 
                                                                } &nbsp;&nbsp;
                                                            */}
                                                                    
                                                                
                                                            </div>
                                                            :
                                                            <div>

                                                                {finduserCourse ?
                                                                    <span className="badge badge-secondary pull-right text-light" style={{cursor: "pointer"}}
                                                                        onClick={() => user ?
                                                                                handleCheckout(n)
                                                                                :
                                                                                toggle2(n)
                                                                            }
                                                                        >
                                                                        Start Learning
                                                                    </span> :

                                                                    <span className="ribbon ribbon-bookmark ribbon-vertical-left ribbon-secondary">
                                                                        <i className="icofont icofont-love">Price: ₹{n.price}</i>
                                                                    </span>
                                                                }
                                                                { changeStatus === true && courseId === n.id  ?
                                                                    <span className="badge badge-secondary pull-right text-light" style={{cursor: "pointer"}}
                                                                     onClick={() => user ?
                                                                                handleCheckout(n)
                                                                                :
                                                                                toggle2(n)
                                                                            }
                                                                            >
                                                                            Start Course</span>
                                                                    : null 
                                                                }
                                                               {/*
                                                                { changeStatus === true && courseId === n.id  ?
                                                                    <span className="badge badge-warning pull-right text-light" 
                                                                        onClick={ () => clickCourseDetail(n)}>
                                                                        Know More
                                                                    </span> 
                                                                    : null 
                                                                } &nbsp;&nbsp; 
                                                                */}
                                                            </div>
                                                        )}
                                                    </h6>
                                                    <p>{n.area}, {n.country}
                                                        <span>
                                                            <i className="fa fa-star font-warning"></i>
                                                            <i className="fa fa-star font-warning"></i>
                                                            <i className="fa fa-star font-warning"></i>
                                                            <i className="fa fa-star font-warning"></i>
                                                            <i className="fa fa-star font-warning"></i>
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <p>
                                                {n.short_note}
                                                <span className="badge badge-warning pull-right text-light" 
                                                    style={{cursor: "pointer"}} onClick={ () => clickCourseDetail(n)}>
                                                    Know More
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div>

            <Modal isOpen={modal2} toggle={toggle2} className="modal-body" centered={true} size="lg">
                <ModalHeader toggle={toggle2}></ModalHeader>
                <ModalBody>

                    <div className="card-body">
                            <Nav tabs className="border-tab-primary">
                                <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                                    <NavLink className={activeTab8 === '1' ? 'active' : ''} onClick={() => setActiveTab8('1')}>
                                        <i className="icofont icofont-ui-home"></i> Login 
                                    </NavLink>
                                </NavItem>
                                <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                                    <NavLink className={activeTab8 === '2' ? 'active' : ''} onClick={() => setActiveTab8('2')}>
                                        <i className="icofont icofont-man-in-glasses"></i> Register
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab8}>
                                <TabPane tabId="1">
                                    {/* <Login courseName={courseName} coursePrice={coursePrice}/> */}
                                    <div className="authentication-box">
                                    <div className="card mt-4">
                                            <div className="card-body">
                                                <div className="text-center">
                                                    <h4>LOGIN</h4>
                                                    <h6>Enter your Username and Password </h6>
                                                </div>
                                                <form className="theme-form">
                                                    <div className="form-group">
                                                        <label className="col-form-label pt-0">Username</label>
                                                        <input className="form-control" type="text" value={loginValue} required=""
                                                            onChange={e => setLoginValue(e.target.value)} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label">Password</label>
                                                        <input className="form-control" type="password" value={passwordValue} required=""
                                                            onChange={e => setPasswordValue(e.target.value)} />
                                                    </div>
                                                    <div className="checkbox p-0">
                                                        <input id="checkbox1" type="checkbox" />
                                                        <label htmlFor="checkbox1">Remember me</label>
                                                    </div>
                                                    <div className="form-group form-row mt-3 mb-0">
                                                        <button className="btn btn-primary btn-block" type="button"
                                                            onClick={() =>
                                                              loginStudent(                                                                
                                                                loginValue,
                                                                passwordValue,
                                                                props.history,
                                                                courseId,
                                                                courseName,
                                                                coursePrice
                                                              )
                                                            }
                                                            >
                                                            Login
                                                        </button>
                                                    </div>
                                                    <div className="form-group form-row mt-3 mb-0">
                                                        <button className="btn btn-secondary btn-block" type="submit">Login With Auth0</button>
                                                    </div>
                                                    <div className="login-divider"></div>
                                                    <div className="social mt-3">
                                                        <div className="form-group btn-showcase d-flex">
                                                            <button className="btn social-btn btn-fb d-inline-block"> <i className="fa fa-facebook"></i></button>
                                                            <button className="btn social-btn btn-twitter d-inline-block"><i className="fa fa-google"></i></button>
                                                            <button className="btn social-btn btn-google d-inline-block"><i className="fa fa-twitter"></i></button>
                                                            <button className="btn social-btn btn-github d-inline-block"><i className="fa fa-github"></i></button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        </div>
                                </TabPane>
                                <TabPane tabId="2">
                                    <Signup />
                                </TabPane>
                            </TabContent>
                        </div>

                    </ModalBody>
                {/* <ModalFooter>
                    <Button color="primary">Save Changes</Button>
                    <Button color="secondary" onClick={toggle2}>Cancel</Button>
                </ModalFooter> */}
            </Modal>
            </div>
          </div>
        </Fragment>
    );
};

export default JobList;