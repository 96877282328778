import React, { Fragment, useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import {TextField, Backdrop , CircularProgress, TablePagination} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ToastContainer, toast } from 'react-toastify';
import {Autocomplete} from '@material-ui/lab';
//import { getVendorForm, getPorders, uploadInvoice, runInvoiceScript, getInvoiceTemp, postInvoiceTemp } from '../../actions/asyncActions';
import { getAllCourses, getAllSections, uploadCourseDocs, getCourseDocs, postCourseDocs } from '../../actions/asyncActions';
import Breadcrumb from '../common/breadcrumb';

const useStyles = makeStyles(theme => ({
  circular: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const InvoiceProcessing = () => {
 
 const classes = useStyles();
 const theme = useTheme();
 const user = JSON.parse(localStorage.getItem("pm_users"));
 //const group_id = user.group_id;
 var [isLoading, setIsLoading] = useState(false);

 const [courses, setCourses] = useState([]);  

  useEffect( () => {
      getAllCourses()
      .then(resp => setCourses(resp.data))
    }, []);
 
  const [course_val, setCourseVal] = useState(null);
  const selectedCourseId = course_val && course_val.id;
  const aaa = course_val && course_val.course_name;
  let bbb = aaa;
  var selectedCourseName = (bbb || '').toUpperCase();

  const [section_val, setSectionVal] = useState(null);
  const selectedSectionId = section_val && section_val.id;

  const [docfile, setDocFile] = React.useState(null);

  const [sections, setSections] = useState([]);
  useEffect( () => {
      getAllSections()
      .then(resp => setSections(resp.data))
    }, []);

  const filterSections = sections.filter(s => s.course_id === selectedCourseId);

  const [course_docs, setCourseDocs] = useState([]);
  useEffect( () => {
      getCourseDocs()
      .then(resp => setCourseDocs(resp.data))
    }, []);

 // const [porders, setPOrders] = useState([]);
  
 // const selectedPO = porder_val && porder_val.id;
 // const project_id = porder_val && porder_val.project_id;
 // const filterPo = porders.filter(x=>x.vendor_id === selectedVendor);
  
 // const [invoiceTemp, setInvoiceTemp] = useState([]);
 // let tempInvoice = (invoiceTemp || []).sort((a,b) => {return b.id-a.id});
 // const abc = tempInvoice || [].status;

 // const templateType = [{ name: 'Template' }, {name: 'General'}];
  const [typeval, setTypeVal] = useState(null);
 // console.log("typeval", typeval);
 const [page, setPage] = React.useState(0);
 const [rowsPerPage, setRowsPerPage] = React.useState(5);

 const handleChangePage = (event, newPage) => {
   setPage(newPage);
 };

 const handleChangeRowsPerPage = event => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
 };

 // useEffect( () => {
 //  getVendorForm(group_id)
 //   .then(resp => setVendors(resp.data))
 // }, []);

 // useEffect( () => {
 //  getPorders(group_id)
 //   .then(resp => setPOrders(resp.data))
 // }, []);

 // useEffect(() => { 
 //  if(abc){
 //  const interval = setInterval(() => { 
 //    getInvoiceTemp(group_id) 
 //    .then(resp => setInvoiceTemp(resp.data))  
 //    }, 5000); 
 //  return () => clearInterval(interval);
 //  }
 //  else {

 //  }
 // }, [invoiceTemp]);


 const handleUploadDoc = () => {
   setDocFile(document.getElementById('docfile').files[0]);
 }

 const handleSubmit = async (event) => {
      const formData = new FormData()
      formData.append( 'file', docfile )
       const doc = await uploadCourseDocs(formData);
       const docName = doc.data.filename;
       const docPath = "/docs/" + docName;

       const docData = {
          doc_file: docPath,
          course_id: selectedCourseId,
          section_id: selectedSectionId,
          user_id: user.id
        }

      postCourseDocs(docData)
      .then(resp => {
       getCourseDocs() 
       .then(resp => setCourseDocs(resp.data))
         setCourseVal(null)
         setSectionVal(null)
         setDocFile(null)
        toast.success("File is successfully uploaded...!",
          {position: toast.POSITION.TOP_CENTER, autoClose: 3000})
      })
  }


  return (
        <Fragment>
         <div className="container-fluid">
            <Breadcrumb title="List View" parent="Job Search" />
            <div className="row">
            {isLoading ? (
              <Backdrop className={classes.circular} open={isLoading}>
                <CircularProgress color="inherit" />
              </Backdrop>
            ) : (
             <div className="col-sm-12 col-xl-6">
               <div className="ribbon-wrapper card">
                <div className="card-body">
                  <div className="ribbon ribbon-bookmark ribbon-primary">Upload Document</div>
                    <div className="col-md-12">
                      <Autocomplete
                        freeSolo
                        options={courses}
                        getOptionLabel={option => option.course_name}
                        value={course_val}                      
                        onChange={(event, newValue) => {
                          setCourseVal(newValue);                          
                        }}            
                        renderInput={params => (
                          <TextField {...params} label="Course" margin="normal" variant="outlined" helperText="Please select Course" fullWidth />
                        )}
                      />
                    </div>
                    { selectedCourseId ?
                      <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <Autocomplete 
                            freeSolo
                            options={filterSections}
                            getOptionLabel={option => option.section_name}
                            value={section_val}                      
                            onChange={(event, newValue) => {
                              setSectionVal(newValue);
                            }}            
                            renderInput={params => (
                              <TextField {...params} label="Section" margin="normal" variant="outlined" helperText="Please select Section" fullWidth />
                            )}
                          />
                      </div>
                      </div>
                      </div>
                      : null
                      }
                    <div className="col-md-12">
                      <label className="col-form-label text-body" htmlFor="message-text">Attach Invoice:</label>
                      <TextField id="docfile" type="file" margin="dense" variant="outlined" onChange={handleUploadDoc} fullWidth/>
                    </div>
                </div>
                {docfile ?
                <div className="card-footer text-center">
                  <button className="btn btn-pill btn-secondary-gradien btn-lg" type="button" onClick={handleSubmit}>Submit</button>
                </div>
                : null
                }
              </div>
             </div>
             )} 

             <div className="col-sm-12 col-xl-6">
              <div className="card">
                <div className="card-block row">
                  <div className="col-sm-12 col-lg-12 col-xl-12">
                    <div className="table-responsive-sm">
                      <table className="table table-bordered table-hover">
                       <thead className="table-info">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">FilePath</th>
                          <th scope="col">SectionId</th>
                          <th scope="col">CourseId</th>
                        </tr>
                       </thead>
                       <tbody>
                        {(course_docs || []).map((n,i) => {
                          return(
                           <tr>
                            <td>{i+1}</td>
                            <td>{n.doc_file}</td>
                            <td>{n.section_id}</td>
                            <td>{n.course_id}</td>                            
                           </tr>
                          )
                         })
                        }
                       </tbody>
                      </table>
                    </div>
                    <div className="col-sm-12 col-lg-12 col-xl-12">
                     <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={course_docs.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                     />
                    </div>
                  </div>
                </div>
              </div>
             </div> 

            </div>
          </div>
        </Fragment>
    );
}
export default InvoiceProcessing;
