import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { TablePagination } from '@material-ui/core';
import { getAllCourses, postCourses, editCourses, updateCourses, deleteCourses } from '../../actions/asyncActions';
import { getCategory, postCategory, editCategory, deleteCategory } from '../../actions/asyncActions';

const MyUser = () => {
    const user = JSON.parse(localStorage.getItem("pm_users"));
    const [category, setCategory] = useState([]);
    const initialFormState = { id: '', name:'', description:'', src:'', status:'' }
    const [ cu_category, setCurrentCourse ] = useState(initialFormState)
    const [modal, setModal] = useState();
    const [addmodal, setAddModal] = useState();
    const [values, setValues] = useState({});

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    useEffect( () => {
     getCategory()
     .then(resp => {
      setCategory(resp.data);
     })
    }, []);

    const handleChange = event => {
        setValues({
          ...values,
          [event.target.name]: event.target.value
        });
    };

    const handleInputChange = event => {
        const { name, value } = event.target
        setCurrentCourse({ ...cu_category, [name]: value })
    }

    const addRow = () => {
     setAddModal(!addmodal)
    }

    const editRow = n => {
     setModal(!modal)
     setCurrentCourse({ id: n.id, name: n.course_name, description: n.description, src:n.src, status:n.status })
    }

    const handleSubmit = () => {
        const userss = JSON.parse(localStorage.getItem("pm_users"));
        const addcat = {
          name: values.name,
          description: values.description,
          src: values.src,
          status: 'show',
          user_id: userss.id
        }
        console.log("addcat", addcat);
        postCategory(addcat)
        .then(resp => {
            getCategory()
            .then(resp => setCategory(resp.data))
            setAddModal(!addmodal)
          })
          .catch(err => {
            console.log("Error", err);
        })
   }

  let userObj = {};
  userObj = (category || []).sort((a,b) => {
    return b.id-a.id;
  });
  console.log("userObj", userObj[0]);

    const handleUpdate = (id, data) => {
       // const user = JSON.parse(localStorage.getItem("pm_users"));
        const bbb = {
          id: id,
          name: data.name,
          description: data.description,
          src: data.src,
          status: data.status
        }
        editCategory(bbb)
        .then(resp => {
              getCategory()
              .then(resp => setCategory(resp.data))
            })
            .catch(err => {
              console.log("Error", err);
            })
        setModal(!modal)
    }

    const deleteRow = (n) => {
      console.log("user_id", n.id)
      const aaa = {
        id: n.id
      }
      deleteCategory(aaa)
      .then(resp => {
        getCategory()
        .then(resp => {
          setCategory(resp.data);
        });
      })
      .catch(err => {
        console.log("Error", err);
      })
    };

        return (
            <Fragment>
            <Breadcrumb title="All Categories" parent="Category" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-sm table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr>
                                                    <th scope="col">
                                                     <input className="checkbox_animated" type="checkbox" />
                                                    </th>
                                                    <th scope="col">#</th>
                                                    <th scope="col">CategoryName</th>
                                                    <th scope="col">Description</th>
                                                    <th scope="col">Src</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {category.filter(s => s.role !== 'superadmin')
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((n, i)=> {
                                                  return (
                                                    <tr>
                                                     <td> <input className="checkbox_animated" type="checkbox" /> </td>
                                                     <th scope="row">{i+1}</th>
                                                     <td>{n.name}</td>
                                                     <td>{n.description}</td>
                                                     <td>{n.src}</td>
                                                     <td>{n.status}</td>
                                                     <td>
                                                      <i className="fa fa-edit" onClick={() => editRow(n)} style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}
                                                      ></i>
                                                      <i className="fa fa-trash" onClick={() => deleteRow(n)} style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}
                                                      ></i>
                                                     </td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-6 col-lg-6 col-xl-6">
                                 <button className="btn btn-pill btn-primary btn-sm" type="button" onClick={() => addRow()}
                                   >
                                   Add Category
                                  </button>
                                </div>
                                <div className="col-sm-6 col-lg-6 col-xl-6">
                                  <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={category.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                   />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* < !-- Using Form Modal for Add Users --> */}
            <Modal isOpen={addmodal} toggle={addRow}>
                <ModalHeader toggle={addRow}>Add Category</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Category Name:</label>
                              <input className="form-control" name="course_name" type="text" placeholder="Category name" 
                               value={values.name} onChange={handleChange}/>
                            </div>                            
                            <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Image Path:</label>
                              <input className="form-control" name="src" type="text" placeholder="Image Path" 
                               value={values.src} onChange={handleChange}/>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Status:</label>
                              <input className="form-control" name="status" type="text" placeholder="Status" 
                               value={values.status} onChange={handleChange}/>
                            </div>
                            <div className="col-md-12 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Description:</label>
                              <input className="form-control" name="description" type="text" placeholder="Description" 
                               value={values.description} onChange={handleChange}/>
                            </div>                            
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSubmit} >Submit</Button>
                    <Button color="secondary" onClick={addRow}>Cancel</Button>
                </ModalFooter>
            </Modal>

            {/* < !-- Using Form Modal for Edit Users --> */}
            <Modal isOpen={modal} toggle={editRow}>
                <ModalHeader toggle={editRow}>Edit Course</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Category Name:</label>
                              <input className="form-control" name="name" type="text" placeholder="Category Name" 
                               value={cu_category.name} onChange={handleInputChange}/>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Image Path:</label>
                              <input className="form-control" name="src" type="text" placeholder="Image Path" 
                               value={cu_category.src} onChange={handleInputChange}/>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Status:</label>
                              <input className="form-control" name="status" type="text" placeholder="Status" 
                               value={cu_category.status} onChange={handleInputChange}/>
                            </div>                            
                            <div className="col-md-12 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Description:</label>
                              <input className="form-control" name="description" type="text" placeholder="Description" 
                               value={cu_category.description} onChange={handleInputChange}/>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={ () => handleUpdate(cu_category.id, cu_category)} >Save Changes</Button>
                    <Button color="secondary" onClick={editRow}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
        );
}

export default MyUser;