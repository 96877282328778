import React, {useContext} from 'react';
import QuizContext from '../context/QuizContext';

function Question(props) {
	const questions = props.questions;
    const {state} = useContext(QuizContext);
    const {currentQuestion} = state;
    console.log("questions", questions);
    const question = questions[currentQuestion];
    console.log("question", question);
   if(question) {
    return <h1>{question.question}</h1>;
	} else{
	return <div></div>;
	}
}

export default Question;
