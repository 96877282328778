import React, { useState } from 'react';
import logo2 from '../assets/images/endless-logo.png';
import logo from '../assets/images/4.png';
import { withRouter } from "react-router";
import { loginStudent } from "../actions/UserContext";
import { signinUser2 } from '../actions/asyncActions';

const Login = (props) => {
    //var userDispatch = useUserDispatch();
    var [loginValue, setLoginValue] = useState("");
    var [passwordValue, setPasswordValue] = useState("");

     //  const location = props.location;
     // const courseName = location.state ? location.state.courseName : 1
     // const coursePrice = location.state ? location.state.coursePrice : 1

      const courseName = props.courseName;
      const coursePrice = props.coursePrice;

    console.log("bbb",props);
    console.log("courseName2",courseName);
    console.log("coursePrice2",coursePrice);
    
   // const loginAuth = () => {
    //     history.push(`${process.env.PUBLIC_URL}/dashboard/default`);
    // }

    async function loginStudent2(login, password, courseName, coursePrice) {
      const data = await signinUser2(login, password)
          if (data) {
            setTimeout(() => {
              localStorage.setItem("id_token", "1");
                props.history.push(`${process.env.PUBLIC_URL}/courses/checkout`, { Name: courseName, Price: coursePrice });      
            }, 2000);
          } else {
            alert("Enetr valid credentials");
          }
    }
    
    return (
        <div>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    {/* <!-- login page start--> */}
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12">
                               {/* <div className="auth-innerright">
                                    <div className="authentication-box">
                                        <div className="text-center"><img src={logo} alt="" /></div> */}
                                        <div className="card mt-4">
                                            <div className="card-body">
                                                <div className="text-center">
                                                    <h4>LOGIN</h4>
                                                    <h6>Enter your Username and Password </h6>
                                                </div>
                                                <form className="theme-form">
                                                    <div className="form-group">
                                                        <label className="col-form-label pt-0">Username</label>
                                                        <input className="form-control" type="text" value={loginValue} required=""
                                                            onChange={e => setLoginValue(e.target.value)} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label">Password</label>
                                                        <input className="form-control" type="password" value={passwordValue} required=""
                                                            onChange={e => setPasswordValue(e.target.value)} />
                                                    </div>
                                                    <div className="checkbox p-0">
                                                        <input id="checkbox1" type="checkbox" />
                                                        <label htmlFor="checkbox1">Remember me</label>
                                                    </div>
                                                    <div className="form-group form-row mt-3 mb-0">
                                                        <button className="btn btn-primary btn-block" type="button" 
                                                           
                                                           onClick={() =>
                                                              loginStudent(                                                                
                                                                loginValue,
                                                                passwordValue,
                                                                props.history
                                                              )
                                                            }
                                                            >Login</button>
                                                    </div>
                                                    
                                                </form>
                                            </div>
                                        </div>

                                    </div>
                              {/*  </div>
                            </div> */}
                        </div>
                    </div>
                    {/* <!-- login page end--> */}
                </div>
            </div>
        </div>
    );
};

//export default withRouter(Login);
export default Login;