import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import JobFilter from '../jobSearch/job-filter';
import jobDB from '../../data/job/jobDB';
import one from '../../assets/images/job-search/1.jpg';
import two from '../../assets/images/job-search/6.jpg';
import { getAllCourses, getAllPayments } from '../../actions/asyncActions';
import { getSingleItem, addToCart } from '../../actions/ecommerce.actions';
import { Navbar, Form, Nav, FormControl, NavDropdown } from 'react-bootstrap';
import nupielogo from '../../pages/nupie.png';
import Razerpaylogo from '../../assets/images/user/user.png';
import UserLogo from '../../assets/images/user/user.png';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem ,Dropdown} from 'reactstrap';

const JobDetail = (props) => {
     const location = props.location
     const id = location.state?location.state.id:1
     const [singleData, setSingleData] = useState('');
     const user = JSON.parse(localStorage.getItem("pm_users"));
     console.log("user", user);

     const [payments, setPayment] = useState([]);

    useEffect( () => {
     getAllPayments()
     .then(resp => {
      setPayment(resp.data);
     })
    }, []);

     const finduserCourse = user ? payments.find(s => s.user_id === user.id && s.course_id===id) : null;
     // const finduserCourse2 = payments.find(s => s.user_id === user.id);
     console.log("id", id);
     console.log("finduserCourse", finduserCourse);
     // console.log("finduserCourse2", finduserCourse2);

    const clickMyCourses = () => {
        props.history.push(`${process.env.PUBLIC_URL}/courses/mycourses`);
    }

    const clickAllCourses = () => {
      props.history.push(`${process.env.PUBLIC_URL}/courses/all_course_list`);
    }

    const logOut =(history) => {
      console.log("history",history)
      localStorage.clear("id_token");
      //localStorage.removeItem("id_token");
      props.history.push(`${process.env.PUBLIC_URL}/pages/home`);
    }

    //const location = props.location
    const courseId = location.state ? location.state.id : 1
    // console.log("location", location);
    // console.log("locationId", catId);

    const [courses, setCourses] = useState([]);  

    useEffect( () => {
        getAllCourses()
        .then(resp => setCourses(resp.data))
      }, []);

    const courseDetail = courses.find(s=> s.id === courseId);
    const courseName = courseDetail && courseDetail.course_name;
    const quantity = 1;
    const courseArea = courseDetail && courseDetail.area;
    const courseCountry = courseDetail && courseDetail.country;
    const courseDesc = courseDetail && courseDetail.description;
    const coursePrice = courseDetail && courseDetail.price;
    console.log("courseDetail", courseDetail);
    console.log("courseName", courseName);

    const similarCourses = courses.filter(s=> s.id != courseId);

    useEffect(() => {
        jobDB.filter((data) => {
            if (data.Id === id) {
                setSingleData(data)
            }
            return 0;
        })
    }, [id]);

    const jobApply = (data) => {
        const id = data.Id
        props.history.push(`${process.env.PUBLIC_URL}/jobSearch/job-apply`,{id});
    }

    const buyProduct = (product, qty) => {
        addToCart(product, qty);
        props.history.push(`${process.env.PUBLIC_URL}/ecommerce/checkout/${product}`);
    }

    const clickApply = () => {
        props.history.push(`${process.env.PUBLIC_URL}/pages/login`);
    }

    const clickApplySignup = () => {
        props.history.push(`${process.env.PUBLIC_URL}/pages/signup`);
    }

    const clickApplyLogo = () => {
        props.history.push(`${process.env.PUBLIC_URL}/pages/home`);
    }

    const openCheckout = () => {
    let options = {
      "key": "rzp_live_gP6iHTYPJs812W",
      "amount": coursePrice * 100, // 2000 paise = INR 20, amount in paisa
      "name": "Rajesh",
      "description": courseName,
      "image": Razerpaylogo,
      "handler": function (response){
        alert(response.razorpay_payment_id);
      },
      "prefill": {
        "name": "Harshil Mathur",
        "email": "harshil@razorpay.com"
      },
      "notes": {
        "address": "Hello World"
      },
      "theme": {
        "color": "#F37254"
      }
   // alert("Paid");
    };

    let rzp = new window.Razorpay(options)
        rzp.open();
    }

    // if(response.razorpay_payment_id)
    // {
        
    // }

    return (
        <Fragment>

        <div className="page-wrapper">
            <div className="container-fluid p-0 m-0">

            <Navbar collapseOnSelect bg="light" variant="light" expand="lg" fixed="top">
                <Navbar.Brand onClick={() => clickApplyLogo()}>
                  <img
                    src={nupielogo}
                    width="30"
                    height="30"
                    style={{cursor: "pointer"}}
                    className="d-inline-block align-top"
                  />{' '} LMS Nu-pie
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                          <Nav.Link onClick={() => clickAllCourses()}>All Courses</Nav.Link>                          
                          {user ?
                            <Nav.Link onClick={() => clickMyCourses()}>My Courses</Nav.Link>
                            : null
                          }
                          {/*<NavDropdown title="Our Blog" id="collasible-nav-dropdown">
                            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                          </NavDropdown> */}
                        </Nav>
                    { user ?
                        <Nav>                        
                           {/* <button className="btn btn-square btn-outline-primary btn-sm" type="button" 
                                onClick={() => logOut(props.history)} >
                                LogOut ({user.firstname})
                              </button>  */}

                            <UncontrolledDropdown setActiveFromChild>
                                <DropdownToggle tag="a" caret>
                                    <img
                                      className="img-30 rounded-circle"
                                      src={UserLogo}
                                      style={{cursor: "pointer"}}
                                      alt="#"
                                    />
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem active>{user.firstname}</DropdownItem>
                                    <DropdownItem onClick={() => clickMyCourses()}>
                                      My Courses
                                    </DropdownItem>
                                    <DropdownItem onClick={() => logOut(props.history)}>
                                      <i className= "icon-power-off"></i> LogOut
                                    </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>                      
                        </Nav>
                        :
                        <Nav>
                          <button className="btn btn-square btn-outline-primary btn-sm" type="button" onClick={() => clickApply()}>
                            Login
                          </button>
                          <button className="btn btn-square btn-warning btn-sm" type="button" onClick={() => clickApplySignup()}>
                            Enroll Today
                          </button>
                        </Nav>
                      }
                    </Navbar.Collapse>
            </Navbar>

        <div className="container-fluid" style={{paddingTop:'20px'}}>

            {/* <Breadcrumb title="Course Details" parent="Course List" /> */}            

            <div className="container-fluid">
                <div className="card">
                    <div className="card-header">
                        <h5 align='center'>Course Detail</h5>
                    </div>
                </div>

                <div className="row">
                    <JobFilter />
                    <div className="col-xl-9 xl-60">
                        <div className="card">
                            <div className="job-search">
                                <div className="card-body">
                                    <div className="media">
                                        <img className="img-40 img-fluid m-r-20" src={one} alt="" />
                                        <div className="media-body">
                                            <h6 className="f-w-600">
                                                <a href="#javascript">{courseName}</a>
                                                <span className="badge badge-primary pull-right">Price: ₹{coursePrice}</span>
                                                {/* <span className="pull-right">
                                                    <button className="btn btn-primary" type="button" onClick={() => jobApply(singleData)}>
                                                        Apply for this job
                                                    </button>
                                                </span> */}
                                            </h6>
                                            <p>{courseArea} - {courseCountry}
                                                <span>
                                                    <i className="fa fa-star font-warning"></i>
                                                    <i className="fa fa-star font-warning"></i>
                                                    <i className="fa fa-star font-warning"></i>
                                                    <i className="fa fa-star font-warning"></i>
                                                    <i className="fa fa-star font-warning"></i>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="job-description">
                                        <h6>Course Description</h6>
                                       {/* <p>{courseDesc}</p> */}
                                        <p>Endless is looking for a UI/UX Designer to join our team. The world is seeing an explosion in the amount and variety of location-baWe are looking for a versatile UX/UI Designer to join our growing design team. Our designers contribute to clients’ projects at all stages of development. We might start from scratch, conducting user and stakeholder interviews, making personas and journey maps, and continue on to iterating on designs and prototypes through delivering final assets for launch. We might come into the middle of an in-flight program-size project and conduct analysis and usability correction or feature enhancement. We might provide research and vision for handoff to an internal development team.</p>
                                    </div>
                                    <div className="job-description">
                                        <h6>Qualifications </h6>
                                        <ul>
                                            <li>Have shipped multiple iOS, Android, and/or web products </li>
                                            <li>5+ years UI/UX experience</li>
                                            <li>Portfolio demonstrating mastery of native iOS, Android, and/or responsive web design principles</li>
                                            <li>Ability to autonomously pursue elegant solutions to open-ended problems</li>
                                            <li>Comfort with ambiguity</li>
                                            <li>Proven ability to create interactive prototypes</li>
                                            <li>Strong verbal communication skills with ability to clearly communicate complex ideas and champion a design vision across all levels of an organization</li>
                                            <li>Strong written communication skills with ability to make transparent design documentation and client-facing presentations</li>
                                            <li>Ability to create and maintain flow charts, wire frames, prototypes, and mockups.</li>
                                            <li>Ability to effectively work on more than one project at a time</li>
                                            <li>Experience conducting user research and stakeholder interviews</li>
                                            <li>Solid grasp of standard design tools, ex: Sketch, Omnigraffle, the Adobe Suite, Zeplin, etc.</li>
                                            <li>Bonus Considerations </li>
                                        </ul>
                                    </div>
                                    <div className="job-description">
                                        <h6>Agency experience</h6>
                                        <ul>
                                            <li>Experience working with Agile development teams</li>
                                            <li>Experience with RITE method usability testing</li>
                                            <li>Experience in visual and motion design; ability to translate UX design into high quality visuals</li>
                                            <li>Mastery of Sketch & InVision</li>
                                            <li>Knowledge of mobile or front-end web programming</li>
                                        </ul>
                                    </div>
                                    <div className="job-description">
                                        <h6>Perks</h6>
                                        <ul>
                                            <li>Competitive pay</li>
                                            <li>Competitive medical, dental, and vision insurance plans</li>
                                            <li>Company-provided 401(k) plan</li>
                                            <li>Paid vacation and sick time</li>
                                            <li>Free snacks and beverages</li>
                                        </ul>
                                    </div>
                                    <div className="job-description">
                                        { !finduserCourse ?
                                            <button className="btn btn-success-gradien m-r-10" type="button" data-original-title="btn btn-info-gradien" title="" onClick={() => openCheckout()}>
                                                Buy Now
                                            </button> :
                                            null
                                        }   
                                        {/*<button className="btn btn-primary-gradien m-r-10" type="button" data-original-title="btn btn-info-gradien" title="" onClick={() => buyProduct(courseName, quantity)}>
                                            Add To Cart
                                        </button>
                                         <button className="btn btn-primary mr-1" type="button"><span><i className="fa fa-check"></i></span> Save this job</button> 
                                        <button className="btn btn-primary" type="button"><span><i className="fa fa-share-alt"></i></span> Share</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="header-faq">
                            <h6 className="mb-0 f-w-600">Similar Courses</h6>
                        </div>
                        <div className="row">
                            {similarCourses.slice(0, 6).map((data, i) => {
                                return (
                                    <div className="col-xl-6 xl-100" key={i}>
                                        <div className="card">
                                            <div className="job-search">
                                                <div className="card-body">
                                                    <div className="media">
                                                        <img className="img-40 img-fluid m-r-20" src={require('../../assets/images/job-search/1.jpg')} alt="" />
                                                        <div className="media-body">
                                                            <h6 className="f-w-600"><a href="#javascript">{data.course_name}</a>
                                                                {(data.badgeType === 'primary' ? <span className="badge badge-primary pull-right">{data.badgeValue}</span>
                                                                    : ''
                                                                )}
                                                            </h6>
                                                            <p>{data.area} <span>{data.country}</span>
                                                                <span>
                                                                    <i className="fa fa-star font-warning"></i>
                                                                    <i className="fa fa-star font-warning"></i>
                                                                    <i className="fa fa-star font-warning"></i>
                                                                    <i className="fa fa-star font-warning"></i>
                                                                    <i className="fa fa-star font-warning-o"></i>
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <p>{data.short_note}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            
                        </div>
                    </div>
                </div>
            </div>
            </div>

            </div>
          </div>
        </Fragment>
    );
};

export default JobDetail;