import React, { Fragment, useState, useEffect } from 'react';
import { Navbar, Form, Nav, FormControl, NavDropdown } from 'react-bootstrap';
import { getAllCourses, getCategory } from '../actions/asyncActions';
import nupielogo from './nupie.png';

import app from "../data/base";
import { Link } from 'react-router-dom';
import { User, Mail, Lock, Settings, LogOut } from 'react-feather';
import { signOut } from "../actions/UserContext";
//import { withRouter } from "react-router";
import Header from './header';
import { AlignLeft, Maximize, Bell, MessageCircle, MoreHorizontal } from 'react-feather';
import UserLogo from '../assets/images/user/user.png';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem ,Dropdown} from 'reactstrap';

const Home = (props) => {
  const user = JSON.parse(localStorage.getItem("pm_users"));
  console.log("user", user);
  //console.log("userId", user.id);

  //const imagePath = `http://localhost:9000`;
 //const pathpath = 'http://172.105.62.208:9004'
 //const pathpath = 'http://172.105.38.239:9004'
 //const invoicePath = `${pathpath}${data.inv_file}`


  const [categories, setCategory] = useState([]); 
  const [courses, setCourses] = useState([]);  

  useEffect( () => {
      getCategory()
      .then(resp => setCategory(resp.data))
    }, []); 

  useEffect( () => {
      getAllCourses()
      .then(resp => setCourses(resp.data))
    }, []);

  console.log("categories", categories);

  const clickApply = () => {
        props.history.push(`${process.env.PUBLIC_URL}/pages/login`);
    }

    const clickApplySignup = () => {
        props.history.push(`${process.env.PUBLIC_URL}/pages/signup`);
    }

    const clickApplyLogo = () => {
        props.history.push(`${process.env.PUBLIC_URL}/pages/home`);
    }

    const clickApplyCourses = (n) => {
        //const id = n.id;
        props.history.push(`${process.env.PUBLIC_URL}/courses/course_list`, { id: n.id });
    }

    const clickAllCourses = () => {
      props.history.push(`${process.env.PUBLIC_URL}/courses/all_course_list`);
    }

    const clickApplyAllCourses = (n) => {
        //const id = n.id;
        props.history.push(`${process.env.PUBLIC_URL}/courses/course_list`, { id: n.id });
    }

    const logOut = () => {
        localStorage.removeItem('profileURL')
        app.auth().signOut()
        props.history.push(`${process.env.PUBLIC_URL}/pages/home`);
    }

    const clickMyCourses = () => {
      props.history.push(`${process.env.PUBLIC_URL}/courses/mycourses`);
    }

    const logOut2 =(history) => {
      console.log("history",history)
      localStorage.clear("id_token");
      //localStorage.removeItem("id_token");
      props.history.push(`${process.env.PUBLIC_URL}/pages/home`);
    }

    return (
        <Fragment>
          <div className="page-wrapper">
            <div className="container-fluid p-0 m-0">
            
              <Navbar collapseOnSelect bg="light" variant="light" expand="lg" fixed="top">
                    <Navbar.Brand onClick={() => clickApplyLogo()}>
                      <img
                        src={nupielogo}
                        width="30"
                        height="30"
                        style={{cursor: "pointer"}}
                        className="d-inline-block align-top"
                      />{' '} LMS Nu-pie
                    </Navbar.Brand>
                      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                      <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                          <Nav.Link onClick={() => clickAllCourses()}>All Courses</Nav.Link>                          
                         {user ?
                          <Nav.Link onClick={() => clickMyCourses()}>My Courses</Nav.Link>
                          : null 
                         }
                          {/*<NavDropdown title="Our Blog" id="collasible-nav-dropdown">
                            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                          </NavDropdown> */}
                        </Nav>

                      { user ?
                        <Nav>                          
                         {/* <button className="btn btn-square btn-outline-primary btn-sm" type="button" 
                            onClick={() => logOut2(props.history)} >
                              <i className= "icon-power-off"></i> LogOut
                          </button>
                          <div className="avatar img-40 rounded-circle" style={{color:'black'}}>
                            <i className="icofont icofont-student-alt"></i>
                            <i className="icofont icofont-ui-user"></i>
                            <i className="fa fa-user"></i>
                            {user.firstname}
                          </div> 

                          <div className="avatar" style={{color:'black'}}>
                            <i className="icofont icofont-student-alt"></i> {user.firstname}
                          </div>
                           */}

                          <UncontrolledDropdown setActiveFromChild>
                            <DropdownToggle tag="a" caret>
                                <img
                                  className="img-30 rounded-circle"
                                  src={UserLogo}
                                  style={{cursor: "pointer"}}
                                  alt="#"
                                />
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem active>{user.firstname}</DropdownItem>
                                <DropdownItem onClick={() => clickMyCourses()}>
                                  My Courses
                                </DropdownItem>
                                <DropdownItem onClick={() => logOut2(props.history)}>
                                  <i className= "icon-power-off"></i> LogOut
                                </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>

                        </Nav>
                        :
                        <Nav>
                          <button className="btn btn-square btn-outline-primary btn-sm" type="button" onClick={() => clickApply()}>
                            Login
                          </button>
                          <button className="btn btn-square btn-warning btn-sm" type="button" onClick={() => clickApplySignup()}>
                            Enroll Today
                          </button>
                        </Nav>
                      }                        
                      </Navbar.Collapse>
                </Navbar>

                <div className="comingsoon comingsoon-bgimg">
                    <div className="comingsoon-inner text-center">
                        <h1 className="display-1 txt-primary">Always be growing.</h1>
                        <h5 className="f-w-300">
                          Structured, online training programs in software development and design for career-minded adults with busy lives.
                        </h5>                           
                        <div>
                          <button className="btn btn-square btn-warning btn-sm" type="button" onClick={() => clickAllCourses()}>
                            Explore Our Courses
                          </button>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5 align='center'>Trending</h5>
                            </div>
                            <div id="aniimated-thumbnials11" className="my-gallery card-body row gallery-with-description">                        
                              {categories.map((n, i) => {
                                  const found = courses.filter(s=> s.cat_id === n.id);
                                  {/* const imagePath = `http://localhost:9000/${n.src}`; */}
                                   const imagePath = `http://172.105.41.149:9000${n.src}`; 
                                   console.log("imagePath", imagePath);
                                  return (
                                      <figure itemProp="associatedMedia" className="col-md-3 col-6 img-hover hover-12"
                                              onClick={ () => clickApplyCourses(n)} style={{cursor: "pointer"}}>
                                          <img
                                              src={imagePath}
                                              alt="Gallery"
                                              className="img-thumbnail"
                                              itemProp="thumbnail"
                                          />
                                          <div className="caption">
                                              <h4>{n.name}</h4>
                                              <p>Over {found.length} Courses</p>
                                          </div>
                                      </figure>

                                      )
                                  })
                              }                                
                            </div>
                        </div>
                    </div>
                </div>

                <footer class="page-footer font-small mdb-color pt-4" style={{backgroundColor:'gray', color:'white'}}>
                  <div class="container-fluid text-center text-md-left">

                    <div class="row">

                      <div class="col-md-6 mt-md-0 mt-3">
                        <h5 class="text-uppercase font-weight-bold">Footer text 1</h5>
                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Expedita sapiente sint, nulla, nihil
                          repudiandae commodi voluptatibus corrupti animi sequi aliquid magnam debitis, maxime quam recusandae
                          harum esse fugiat. Itaque, culpa?</p>
                      </div>

                      <hr class="clearfix w-100 d-md-none pb-3"/>

                      <div class="col-md-6 mb-md-0 mb-3">
                        <h5 class="text-uppercase font-weight-bold">Footer text 2</h5>
                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Optio deserunt fuga perferendis modi earum
                          commodi aperiam temporibus quod nulla nesciunt aliquid debitis ullam omnis quos ipsam, aspernatur id
                          excepturi hic.</p>
                      </div>

                    </div>

                  </div>

                  <div class="footer-copyright text-center py-3">Copyright 2020 © 
                    <a href="http://nu-pie.com/">Nu-pie</a> All rights reserved.
                  </div>
                </footer>
                
            </div>
          </div>
        </Fragment>
    );
};

export default Home;