import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import { Container, Button, Collapse, Card, CardBody } from 'reactstrap';
import { HelpCircle, ArrowDown, ArrowUp, DollarSign } from 'react-feather';
import { getModels, getCourseVideos, fetchLMSVideo } from '../../../actions/asyncActions';
import { Accordion, AccordionItem } from 'react-light-accordion';
import 'react-light-accordion/demo/css/index.css';
import bitcoin1 from '../../../assets/images/bitcoin/1.png';

const Models = (props) => {
    const location = props.location
    const Courseid = location.state?location.state.id:1;
    console.log("CourseId", Courseid)

    const user = JSON.parse(localStorage.getItem("pm_users"));
    console.log("user", user);

    const [courseModels, setModels] = useState([]);
    const [courseVideos, setCourseVideos] = useState([]); 
    const [lmsVideo, setLMSvideo] = useState([]); 

    useEffect( () => {
        getModels(Courseid)
        .then(resp => setModels(resp.data))
      }, []);

    useEffect( () => {
        getCourseVideos()
        .then(resp => setCourseVideos(resp.data))
      }, []);

    console.log("courseModels", courseModels);
    console.log("courseVideos", courseVideos);

    const clickApply2 = (n) => {
        //const sec_Courses = courseVideos.filter(a => a.section_id === n.id);
        const Mid = n.id;
        const Mname = n.name;
        const id = courseVideos[0].id;
        const VideoName = courseVideos[0].video_name;
        console.log("aaa", courseVideos[0]);
        console.log("aaa111", courseVideos[0].id);
        console.log("video_name", courseVideos[0].video_name);
        fetchLMSVideo(VideoName)
        .then(resp => 
        {
            setLMSvideo(resp.data);
            console.log("resp", resp);
            console.log("RespData", resp.data);
            props.history.push(`${process.env.PUBLIC_URL}/courses/course_learning`, { id, Mid, Mname, Courseid, url: resp.url, reapData:resp.data });
        })
    }

    const clickApply = (s) => {
        const id = s.id
        const VideoName = s.video_name;
        console.log("VideoName", VideoName);
        fetchLMSVideo(VideoName)
        .then(resp => 
        {
            setLMSvideo(resp.data);
            console.log("resp", resp);
            console.log("RespData", resp.data);
            props.history.push(`${process.env.PUBLIC_URL}/learning/learning-detail`, { id, url: resp.url, reapData:resp.data });
        })
    }  

    const clickAssesments = (id) => {
        props.history.push(`${process.env.PUBLIC_URL}/assesments/assesments`, { id });
    }

    const clickDocuments = (id) => {
        props.history.push(`${process.env.PUBLIC_URL}/courses/docView`, { id });
    }

    const goBack = () => {
        props.history.push(`${process.env.PUBLIC_URL}/courses/mycourses`);
     }

    return (
        <Fragment>
            <Breadcrumb title="Models" parent="Courses" />
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                    <div className="header-faq">
                        {/* <h5 className="mb-0">Models</h5> */}
                        <button className="btn btn-square btn-outline-info btn-sm" onClick={goBack} >Back</button>
                    </div>

                    <div className="col-md-12 xl-100">
                        <div className="row">
                            {courseModels.map((n, i) => { 
                                    return (
                                    <div className="col-sm-4" style={{marginBottom:'10px', marginTop:'10px', cursor:"pointer"}} onClick={ () => clickApply2(n)}>
                                        <div className="card-body bg-secondary">
                                            <div className="icons-section text-center">
                                                <img src={bitcoin1} alt="" />
                                                <h5 style={{marginTop:'10px'}}>{n.name}</h5>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                })}

                        </div> 
                    </div>   

                </div>

              </div>
            </div>
        
        </Fragment>
    );
};

export default Models;