import React from "react";
import { signinUser2 } from './asyncActions';

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    // case "CHECKOUT_SUCCESS":
    //   return { ...state, cart_items: action.data };
    // case "PLACE_ORDER":
    //   return { ...state, cart_items: [] };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("id_token"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

// const onKeyDown = (e) => {

//   }

//   const pressEnter = (e) => {
//     e.preventDefault();
//   }

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut, loginStudent };

// ###########################################################

async function loginUser(login, password, history) {
  //pressEnter(e);
  // setError(false);
  // setIsLoading(true);
  const data = await signinUser2(login, password)
  if (data) {
    setTimeout(() => {
      localStorage.setItem("id_token", "1");
     // const data = await signinUser2(login, password)
    //  dispatch({ type: "LOGIN_SUCCESS" });
      // setError(null);
      // setIsLoading(false);

      // if(data.user.type === 'ck')
      // {
        history.push(`${process.env.PUBLIC_URL}/pages/home`);
      // } else {
      //   history.push(`/${data.user.group_id}/pos/storedashboard`);
      // }
      
    }, 2000);
  } else {
   // dispatch({ type: "LOGIN_FAILURE" });
    alert("Enetr valid credentials");
    // setError(true);
    // setIsLoading(false);
  }
}

async function loginStudent(login, password, history, courseId, courseName, coursePrice) {
  const data = await signinUser2(login, password)
  if (data) {
    setTimeout(() => {
      localStorage.setItem("id_token", "1");
        history.push(`${process.env.PUBLIC_URL}/courses/checkout`, { courseId:courseId, courseName:courseName, coursePrice: coursePrice });      
    }, 2000);
  } else {
   // dispatch({ type: "LOGIN_FAILURE" });
    alert("Enetr valid credentials");
    // setError(true);
    // setIsLoading(false);
  }
}

function signOut(history) {
  localStorage.removeItem("id_token");
  //dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push(`${process.env.PUBLIC_URL}/pages/home`);
  // history.push("/login");
}

// function CartItems(dispatch, user_id, store_id, history, setIsLoading, setError) {
//   const data = await getCheckout(user_id, store_id)
//   dispatch({ type: "CHECKOUT_SUCCESS", data });
  
  
// }
