import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import LearningFilter from './learning-filter';
import learningOne from '../../assets/images/faq/learning-1.png';
import VideoPoster from '../../assets/images/lightgallry/cat2.jpg';
import four from '../../assets/images/blog/4.jpg';
import learningDB from '../../data/learning/learningDB';
import {useVideo} from 'react-use';

import VideoPlayer from 'react-video-markers';
import { Button, Collapse } from 'reactstrap';

import { Player, LoadingSpinner, ControlBar, ReplayControl, ForwardControl, VolumeMenuButton  } from 'video-react';
//import '~video-react/dist/video-react.css';
import { getCourseVideo, fetchLMSVideo, getCourseVideos } from '../../actions/asyncActions';
import { HelpCircle } from 'react-feather';

const LearningDeatil = (props) => {

    const location = props.location
    const Videourl = location.state.url;
    // const RespData = location.state.RespData;
    // console.log("RespData", RespData);
    const id = location.state ? location.state.id : 1
    const Sid = location.state ? location.state.Sid : 1
    const Sname = location.state.Sname;
    console.log("Sname", Sname);

    const [singleData, setSingleData] = useState('');
    const [isFilter, setIsFilter] = useState(true);

    const [courseVideos, setCourseVideos] = useState([]); 
    const [lmsVideo, setLMSvideo] = useState([]); 

    useEffect( () => {
        getCourseVideos()
        .then(resp => setCourseVideos(resp.data))
      }, []);

    const sec_Courses = courseVideos.filter(a => a.section_id === Sid);

    

    useEffect(() => {
        learningDB.filter((data) => {
            if (data.Id === id) {
                setSingleData(data)
            }
            return 0;
        })
    }, [id]);

    const [video, state, controls, ref] = useVideo(
        <video src= {Videourl}
         autoPlay nofullscreen  height='50%' width='100%' controls/>
      );

      const [isPlaying, setState] = useState(false);
      const [volume, setVolumeState] = useState(0.7);
      
      const handlePlay = () => {
        setState({isPlaying: true});
      };
      
      const handlePause = () => {
        setState({isPlaying: false});
      };
      
      const handleVolume = value => {
        setVolumeState({volume: value});
      };

      const clickApply = (s) => {
        const Sid = location.state ? location.state.Sid : 1;
        const Sname = location.state.Sname;
        const id = s.id
        const VideoName = s.video_name;
        console.log("VideoName", VideoName);
        fetchLMSVideo(VideoName)
        .then(resp => 
        {
            setLMSvideo(resp.data);
            console.log("resp", resp);
            console.log("RespData", resp.data);
            props.history.push(`${process.env.PUBLIC_URL}/learning/learning-detail`, { id, Sid, Sname, url: resp.url, reapData:resp.data });
        })
    }

    const goBack = () => {
        props.history.push(`${process.env.PUBLIC_URL}/courses/course_sections`);
     }

    const clickAssesments = (id) => {
        props.history.push(`${process.env.PUBLIC_URL}/assesments/assesments`, { id });
    }

    const clickDocuments = (id) => {
        props.history.push(`${process.env.PUBLIC_URL}/courses/docView`, { id });
    }

    return (
        <Fragment>
            <Breadcrumb title="Detail Course" parent="Learning" />
            <div className="container-fluid">
                <div className="header-faq">
                        {/* <h5 className="mb-0">Sections</h5> */}
                        <button className="btn btn-square btn-outline-info btn-sm" onClick={goBack} >Back</button>
                    </div>
                <div className="row">
                    <div className="col-xl-9 xl-60">

                        <div>
                            <Player
                                
                              autoPlay playsInline
                              // startTime={50}
                              src={Videourl}
                            >
                              <LoadingSpinner />
                              <ControlBar>
                                <VolumeMenuButton />
                                <ReplayControl seconds={5} order={2.1} />
                                <ForwardControl seconds={5} order={3.1} />
                              </ControlBar>
                            </Player>
                        </div>

                    </div>

                   {/* <LearningFilter /> */}
                

        <div className="col-xl-3 xl-40">
                <div className="default-according style-1 faq-accordion job-accordion" id="accordionoc">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">

                                <div className="card-header">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link pl-0" data-toggle="collapse" onClick={() => setIsFilter(!isFilter)}
                                            data-target="#collapseicon" aria-expanded={isFilter} aria-controls="collapseicon">{Sname}</button>
                                    </h5>
                                </div>
                                <Collapse isOpen={isFilter}>
                                    <div className="collapse show" id="collapseicon" aria-labelledby="collapseicon" data-parent="#accordion">
                                        <div className="card-body">
                                            <div className="checkbox-animated">
                                                <div className="learning-header"><span className="f-w-600">Videos</span></div>

                                                {sec_Courses.map((s, i) => {
                                                        return (  
                                                            <div className="checkbox-animated mt-0" onClick={ () => clickApply(s)}>
                                                              <div className="media">
                                                                  <div className="top-server">
                                                                      <div className="learning-header"><span className="mb-0">{s.video_name}</span></div>
                                                                  </div>
                                                              </div>
                                                            </div> 
                                                        )
                                                    })}

                                            </div>

                                            <div className="checkbox-animated mt-0" onClick={ () => clickAssesments(Sid)}>
                                              <div className="media">
                                                  <div className="top-server">
                                                      <div className="learning-header"><span className="f-w-600">Assesments</span></div>
                                                  </div>
                                              </div>
                                            </div>
                                            <div className="checkbox-animated mt-0" onClick={ () => clickDocuments(Sid)}>
                                              <div className="media">
                                                  <div className="top-server">
                                                      <div className="learning-header"><span className="f-w-600">Documentation</span></div>
                                                  </div>
                                              </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </Collapse>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            </div>
            </div>
        </Fragment>
    );
};

export default LearningDeatil;