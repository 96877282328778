import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Container, Button, Collapse, Card, CardBody } from 'reactstrap';
import { HelpCircle, ArrowDown, ArrowUp, DollarSign } from 'react-feather';
import { getSections, getCourseVideos, fetchLMSVideo } from '../../actions/asyncActions';
import { Accordion, AccordionItem } from 'react-light-accordion';
import 'react-light-accordion/demo/css/index.css';
import bitcoin1 from '../../assets/images/bitcoin/1.png';

const JobList = (props) => {
    const location = props.location
    const Courseid = location.state?location.state.id:1;
    console.log("CourseId", Courseid)

    const user = JSON.parse(localStorage.getItem("pm_users"));
    console.log("user", user);

    const [sections, setSections] = useState([]);
    const [courseVideos, setCourseVideos] = useState([]); 
    const [lmsVideo, setLMSvideo] = useState([]); 

    useEffect( () => {
        getSections(Courseid)
        .then(resp => setSections(resp.data))
      }, []);

    useEffect( () => {
        getCourseVideos()
        .then(resp => setCourseVideos(resp.data))
      }, []);

    console.log("sections", sections);
    console.log("courseVideos", courseVideos);

    const clickApply2 = (n) => {
        const sec_Courses = courseVideos.filter(a => a.section_id === n.id);
        const Sid = n.id;
        const Sname = n.section_name;
        const id = sec_Courses[0].id;
        const VideoName = sec_Courses[0].video_name;
        console.log("aaa", sec_Courses[0]);
        console.log("aaa111", sec_Courses[0].id);
        console.log("video_name", sec_Courses[0].video_name);
        fetchLMSVideo(VideoName)
        .then(resp => 
        {
            setLMSvideo(resp.data);
            console.log("resp", resp);
            console.log("RespData", resp.data);
            props.history.push(`${process.env.PUBLIC_URL}/learning/learning-detail`, { id, Sid, Sname, url: resp.url, reapData:resp.data });
        })
    }

    const clickApply = (s) => {
        const id = s.id
        const VideoName = s.video_name;
        console.log("VideoName", VideoName);
        fetchLMSVideo(VideoName)
        .then(resp => 
        {
            setLMSvideo(resp.data);
            console.log("resp", resp);
            console.log("RespData", resp.data);
            props.history.push(`${process.env.PUBLIC_URL}/learning/learning-detail`, { id, url: resp.url, reapData:resp.data });
        })
    }  

    const clickAssesments = (id) => {
        props.history.push(`${process.env.PUBLIC_URL}/assesments/assesments`, { id });
    }

    const clickDocuments = (id) => {
        props.history.push(`${process.env.PUBLIC_URL}/courses/docView`, { id });
    }

    const goBack = () => {
        props.history.push(`${process.env.PUBLIC_URL}/courses/mycourses`);
     }

    return (
        <Fragment>
            <Breadcrumb title="Sections" parent="Courses" />
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                    <div className="header-faq">
                        {/* <h5 className="mb-0">Sections</h5> */}
                        <button className="btn btn-square btn-outline-info btn-sm" onClick={goBack} >Back</button>
                    </div>

                    <div className="col-md-12 xl-100">
                        <div className="row">
                            {sections.map((n, i) => { 
                                    const Sid = n.id;
                                    return (

                            <div className="col-sm-4" style={{marginBottom:'10px', marginTop:'10px'}} onClick={ () => clickApply2(n)}>
                                <div className="card-body bg-secondary">
                                    <div className="icons-section text-center">
                                        <img src={bitcoin1} alt="" />
                                        <h5 style={{marginTop:'10px'}}>{n.section_name}</h5>
                                    </div>
                                </div>
                            </div>
                            )
                                })}

                        </div> 
                    </div>   

                </div>

              </div>
            </div>
        
        </Fragment>
    );
};

export default JobList;