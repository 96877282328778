import axios from 'axios';

let baseURL = 'http://localhost:9000/api';

const url = 'https://api.linode.com/v4/object-storage/buckets/us-east-1/nupie-videos/object-list';

const Video_url = 'https://api.linode.com/v4/object-storage/buckets/us-east-1/nupie-videos/object-url';

if(process.env.NODE_ENV !== "development") {
  baseURL = "/api";
}
const instance = axios.create({
   withCredentials: true
 });

const makeRequest = (type, url, data) => {
  return instance[type](baseURL+url, data)
}


export function fetchLMSlist() {
  return fetch(`${url}`, {
    method: 'GET',
    headers: new Headers({
      Authorization: 'Bearer 6b870f1242fc82217a94965008112f150b654ac5f5b5fe7360a1e82561920290',
    }),
  })
    .then((response) => response.json());
}

export function fetchLMSVideo(CourseName) {
  return fetch(`${Video_url}`, {
    method: 'POST',
    headers: { 
      'Content-Type': 'application/json',
      'Authorization': 'Bearer 6b870f1242fc82217a94965008112f150b654ac5f5b5fe7360a1e82561920290' 
    },
    body: JSON.stringify(
      {
        method: 'GET',
        name: CourseName,
        expires_in: 3600
    })
  })
    .then((response) => response.json());
}

export const signinUser2 = (username, password) => {
  return makeRequest('post', '/login', {username, password})
  .then(resp => {
    if(resp.data){
      localStorage.setItem("s_token", resp.data.user.token);
      console.log("token", resp);
      localStorage.setItem("pm_users", JSON.stringify(resp.data.user));
      return resp.data;
    }
  })
}

/*** Users ***/
export const getAllUser = () => {
  return makeRequest('get', `/users`);
}
export const getUser = (user_id) => {
  return makeRequest('get', `/user/${user_id}`);
}
export const postUsers = (data) => {
  return makeRequest('post', '/users', data);
}
export const editUsers = (data) => {
  return makeRequest('put', '/users', data);
}
export const updateUsers = (data) => {
  return makeRequest('put', '/update_users', data);
}
export const deleteUsers = (data) => {
  return makeRequest('delete', '/users', {data: data});
}

/* Category */
export const getCategory = () => {
  return makeRequest('get', `/categories`);
}
export const postCategory = (data) => {
  return makeRequest('post', '/categories', data);
}
export const editCategory = (data) => {
  return makeRequest('put', '/categories', data);
}
export const deleteCategory = (data) => {
  return makeRequest('delete', '/categories', {data: data});
}

/*** Courses ***/
export const getAllCourses = () => {
  return makeRequest('get', `/courses`);
}
export const postCourses = (data) => {
  return makeRequest('post', '/courses', data);
}
export const editCourses = (data) => {
  return makeRequest('put', '/courses', data);
}
export const updateCourses = (data) => {
  return makeRequest('put', '/update_courses', data);
}
export const deleteCourses = (data) => {
  return makeRequest('delete', '/courses', {data: data});
}

/*** Assesments ***/ 
export const getAllAssesments = () => {
  return makeRequest('get', `/assesments`);
}
// export const getAssesments = (user_id) => {
//   return makeRequest('get', `/assesments/${user_id}`);
// }
export const postAssesments = (data) => {
  return makeRequest('post', '/assesments', data);
}
export const editAssesments = (data) => {
  return makeRequest('put', '/assesments', data);
}
export const deleteAssesments = (data) => {
  return makeRequest('delete', '/assesments', {data: data});
}

/*** Payments ***/
export const getAllPayments = () => {
  return makeRequest('get', `/payments`);
}
export const postPayments = (data) => {
  return makeRequest('post', '/payments', data);
}
export const editPayments = (data) => {
  return makeRequest('put', '/payments', data);
}
export const deletePayments = (data) => {
  return makeRequest('delete', '/payments', {data: data});
}
export const postUserCourses = (data) => {
  return makeRequest('post', '/user_courses', data);
}

/*** Sections ***/
export const getAllSections = () => {
  return makeRequest('get', `/sections`);
}
export const getSections = (course_id) => {
  return makeRequest('get', `/sections/${course_id}`);
}
export const postSections = (data) => {
  return makeRequest('post', '/sections', data);
}
export const editSections = (data) => {
  return makeRequest('put', '/sections', data);
}
export const deleteSections = (data) => {
  return makeRequest('delete', '/sections', {data: data});
}

/*** Models ***/
export const getAllModels = () => {
  return makeRequest('get', `/models`);
}
export const getModels = (course_id) => {
  return makeRequest('get', `/models/${course_id}`);
}
export const postModels = (data) => {
  return makeRequest('post', '/models', data);
}
export const editModels = (data) => {
  return makeRequest('put', '/models', data);
}
export const deleteModels = (data) => {
  return makeRequest('delete', '/models', {data: data});
}

/*** Course Videos ***/
export const getCourseVideos = () => {
  return makeRequest('get', `/course_videos`);
}
export const postCourseVideos = (data) => {
  return makeRequest('post', '/course_videos', data);
}
export const editCourseVideos = (data) => {
  return makeRequest('put', '/course_videos', data);
}
export const deleteCourseVideos = (data) => {
  return makeRequest('delete', '/course_videos', {data: data});
}

/*** Course Docs ***/
export const getCourseDocs = () => {
  return makeRequest('get', `/course_docs`);
}
export const postCourseDocs = (data) => {
  return makeRequest('post', '/course_docs', data);
}
export const editCourseDocs = (data) => {
  return makeRequest('put', '/course_docs', data);
}
export const deleteCourseDocs = (data) => {
  return makeRequest('delete', '/course_docs', {data: data});
}
export const uploadCourseDocs = (data) => {
  return makeRequest('post', '/upload_docs', data);
}

/*** User videos ***/
export const getUserVideos = () => {
  return makeRequest('get', `/user_videos`);
}
export const postUserVideos = (data) => {
  return makeRequest('post', '/user_videos', data);
}
export const editUserVideos = (data) => {
  return makeRequest('put', '/user_videos', data);
}
export const deleteUserVideos = (data) => {
  return makeRequest('delete', '/user_videos', {data: data});
}


/*** Settings ***/
export const fetchAllSettings = (lab_id) => {
  return makeRequest('get', `/fet_settings/${lab_id}`);
}
export const fetchSetting = (lab_id) => {
  return makeRequest('get', `/settings/${lab_id}`);
}
export const postSettings = (data) => {
  return makeRequest('post', '/settings', data);
}
export const editSettings = (data) => {
  return makeRequest('put', '/settings', data);
}

export const uploadLogo = (data) => {
  return makeRequest('post', '/logo', data);
}