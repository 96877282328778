import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import { Container, Button, Collapse, Card, CardBody } from 'reactstrap';
import { HelpCircle } from 'react-feather';
import { getCourseDocs } from '../../../actions/asyncActions';
import { Accordion, AccordionItem } from 'react-light-accordion';
import 'react-light-accordion/demo/css/index.css';
import { Document, Page, pdfjs } from 'react-pdf';

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';

//import PDFViewer from 'pdf-viewer-reactjs'


const DocumentView = (props) => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const location = props.location
    const SectionId = location.state?location.state.id:1;
    console.log("SectionId", SectionId)

    const user = JSON.parse(localStorage.getItem("pm_users"));
    console.log("user", user);

    const [courseDocs, setDocs] = useState([]); 

    useEffect( () => {
        getCourseDocs()
        .then(resp => setDocs(resp.data))
      }, []);

    const filterDoc = courseDocs.find(s => s.section_id === SectionId);
    const docFile = filterDoc && filterDoc.doc_file;
    //const docPath = `http://localhost:9000/${docFile}`;
    //const Path = `http://localhost:9000`;
    const Path = `http://172.105.41.149:9000`;
    
    const docPath = `${Path}${docFile}`
    console.log("docFile", docFile)
    console.log("Path", Path)
    console.log("docPath", docPath)
    
    const [numPages, setNumPage] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

     const onDocumentLoadSuccess = ({ numPages }) => {
       setNumPage(numPages);
     }

     const goToPrevPage = () => {
      setPageNumber(pageNumber - 1);
     }
     const goToNextPage = () => {
      setPageNumber(pageNumber + 1);
     }

     const goBack = () => {
        props.history.push(`${process.env.PUBLIC_URL}/courses/course_models`);
     }

    return (
        <Fragment>
            <Breadcrumb title="Document" parent="Courses" />
          {docFile ?
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12 col-xl-6">
                
                  <nav>
                    <button className="btn btn-square btn-outline-info btn-sm" onClick={goBack}>Back</button>&nbsp;&nbsp;
                    <button className="btn btn-light btn-sm" onClick={goToPrevPage}>Prev</button>&nbsp;&nbsp;
                    <button className="btn btn-secondary btn-sm" onClick={goToNextPage}>Next</button>
                  </nav>
                  <Document
                    file={`${docPath}`}
                    onLoadSuccess={onDocumentLoadSuccess}
                   >
                    <Page pageNumber={pageNumber} />
                  </Document>
                  <p>Page {pageNumber} of {numPages}</p>
                  
                </div>
              </div>
            </div>

            :

            <section className="cart-section section-b-space">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                          <button className="btn btn-square btn-outline-info" onClick={goBack}>Back</button>&nbsp;&nbsp;
                            <div >
                                <div className="col-sm-12 empty-cart-cls text-center">
                                    <h3>
                                        <strong>There is no Documentation</strong>
                                    </h3>
                                    <h4>Explore more shortlist some courses.</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
          }
        
        </Fragment>
    );
};

export default DocumentView;