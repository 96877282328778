import React, { Fragment, useState, useEffect } from 'react';
import { getCartTotal } from "../../services/index";
import Breadcrumb from '../common/breadcrumb';
import { useSelector } from 'react-redux';
import useForm from 'react-hook-form'
import { Navbar, Form, Nav, FormControl, NavDropdown } from 'react-bootstrap';
import Razerpaylogo from '../../assets/images/user/user.png';
import nupielogo from '../../pages/nupie.png';
import UserLogo from '../../assets/images/user/user.png';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem ,Dropdown} from 'reactstrap';
import { postPayments, getAllPayments, postUserCourses } from '../../actions/asyncActions';

const Checkout = (props) => {
    const location = props.location

    const user = JSON.parse(localStorage.getItem("pm_users"));
    const courseId = location.state?location.state.courseId:1
    const courseName = location.state ? location.state.courseName : 1
    const coursePrice = location.state ? location.state.coursePrice : 1
    console.log("courseName",courseName)
    console.log("coursePrice",coursePrice)
    console.log("courseId",courseId)

    const [payments, setPayment] = useState([]);

    useEffect( () => {
     getAllPayments()
     .then(resp => {
      setPayment(resp.data);
     })
    }, []);

    const [values, setValues] = useState({});
    const handleChange = event => {
        setValues({
          ...values,
          [event.target.name]: event.target.value
        });
    };

    const { register, handleSubmit, errors } = useForm();

    const onSubmit = data => {
        const userCourseData = {
            first_name: values.firstName,
            last_name: values.lastName,
            phone: values.phone,
            email: values.email,
            country: values.country,
            address: values.address,
            status: "Paid",            
            course_id: courseId,
            user_id: user.id
        }

        const paymentData = {
            course_name: courseName,
            price: coursePrice,
            subtotal: coursePrice,
            discount: 0,
            total: coursePrice,
            status: "Paid",
            course_id: courseId,
            user_id: user.id
        }
        console.log("paymentData", paymentData)

        if (data !== '') {
            //alert('You submitted the form and stuff!');
            let options = {
              "key": "rzp_live_gP6iHTYPJs812W",
              "amount": 1 * 100, // 2000 paise = INR 20, amount in paisa
              "name": values.firstName,
              "description": courseName,
              "image": Razerpaylogo,
              "handler": function (response){
                //alert(response.razorpay_payment_id);
                if(response.razorpay_payment_id){
                    postUserCourses(userCourseData)
                    postPayments(paymentData)
                    .then(resp => {
                        getAllPayments()
                        .then(resp => setPayment(resp.data))
                      })
                      .catch(err => {
                        console.log("Error", err);
                    })
                }
                props.history.push(`${process.env.PUBLIC_URL}/ecommerce/invoice`);
              },
              "prefill": {
                "name": "Harshil Mathur",
                "email": values.email
              },
              "notes": {
                "address": "Hello World"
              },
              "theme": {
                "color": "#F37254"
              }
           // alert("Paid");
            };

            let rzp = new window.Razorpay(options)
            rzp.open();

           // props.history.push(`${process.env.PUBLIC_URL}/ecommerce/invoice`);
        } else {
            errors.showMessages();
        }
    };

    const cart = useSelector(content => content.data.cart);
    const symbol = useSelector(content => content.data.symbol);
    const [obj, setObj] = useState({});

    const setStateFromInput = (event) => {
        obj[event.target.name] = event.target.value;
        setObj(obj);
    }

    const renderLink = () => {
        props.history.push(`${process.env.PUBLIC_URL}/ecommerce/product`);
    }

    const openCheckout = () => {
        let options = {
          "key": "rzp_live_gP6iHTYPJs812W",
          "amount": 20 * 100, // 2000 paise = INR 20, amount in paisa
          "name": "Rajesh",
          "description": courseName,
          "image": Razerpaylogo,
          "handler": function (response){
            alert(response.razorpay_payment_id);
          },
          "prefill": {
            "name": "Harshil Mathur",
            "email": "harshil@razorpay.com"
          },
          "notes": {
            "address": "Hello World"
          },
          "theme": {
            "color": "#F37254"
          }
       // alert("Paid");
        };

        let rzp = new window.Razorpay(options)
            rzp.open();
    }

    const clickApply = () => {
        props.history.push(`${process.env.PUBLIC_URL}/pages/login`);
    }

    const clickApplySignup = () => {
        props.history.push(`${process.env.PUBLIC_URL}/pages/signup`);
    }

    const clickApplyLogo = () => {
        props.history.push(`${process.env.PUBLIC_URL}/pages/home`);
    }

    const clickMyCourses = () => {
      props.history.push(`${process.env.PUBLIC_URL}/courses/mycourses`);
    }

    const logOut2 =(history) => {
      console.log("history",history)
      localStorage.clear("id_token");
      //localStorage.removeItem("id_token");
      props.history.push(`${process.env.PUBLIC_URL}/pages/home`);
    }

    return (
        <Fragment>

            <Navbar collapseOnSelect bg="light" variant="light" expand="lg" fixed="top">
                <Navbar.Brand onClick={() => clickApplyLogo()}>
                  <img
                    src={nupielogo}
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                  />{' '} LMS Nu-pie
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                          <Nav.Link href="#features">Programs</Nav.Link>
                          <Nav.Link href="#pricing">Alumni</Nav.Link>
                          <NavDropdown title="Courses" id="collasible-nav-dropdown">
                            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                          </NavDropdown>
                        </Nav>

                    { user ?
                        <Nav>                        
                           {/* <button className="btn btn-square btn-outline-primary btn-sm" type="button" 
                                onClick={() => logOut2(props.history)} >
                                    LogOut ({user.firstname})
                              </button> */}

                            <UncontrolledDropdown setActiveFromChild>
                                <DropdownToggle tag="a" caret>
                                    <img
                                      className="img-30 rounded-circle"
                                      src={UserLogo}
                                      alt="#"
                                    />
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem active>{user.firstname}</DropdownItem>
                                    <DropdownItem onClick={() => clickMyCourses()}>
                                      My Courses
                                    </DropdownItem>
                                    <DropdownItem onClick={() => logOut2(props.history)}>
                                      <i className= "icon-power-off"></i> LogOut
                                    </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>

                        </Nav>
                        :
                        <Nav>
                          <button className="btn btn-square btn-outline-primary btn-sm" type="button" onClick={() => clickApply()}>
                            Login
                          </button>
                          <button className="btn btn-square btn-warning btn-sm" type="button" onClick={() => clickApplySignup()}>
                            Enroll Today
                          </button>
                        </Nav>
                      }     

                    </Navbar.Collapse>
            </Navbar>

            {/* <Breadcrumb title="Check out" parent="Ecommerce" /> */}
            <div className="container-fluid" style={{paddingTop:'20px'}}>
                <div className="card checkout">
                    <div className="card-header">
                        <h5>Billing Details</h5>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-6 col-sm-12">
                                <form onSubmit={handleSubmit(onSubmit)} className="needs-validation">
                                    <div className="form-row">
                                        <div className="form-group col-sm-6">
                                            <label className="field-label">First Name</label>
                                            <input className="form-control" type="text" name="firstName" ref={register({ required: true })}
                                                value={values.firstName} onChange={handleChange} />
                                            <span>{errors.firstName && 'First name is required'}</span>
                                        </div>
                                        <div className="form-group col-sm-6">
                                            <label className="field-label">Last Name</label>
                                            <input className="form-control" type="text" name="lastName" ref={register({ required: true })}
                                                value={values.lastName} onChange={handleChange} />
                                            <span>{errors.lastName && 'Last name is required'}</span>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-sm-6">
                                            <label>Phone</label>
                                            <input className="form-control" type="text" name="phone" ref={register({ pattern: /\d+/ })} 
                                                value={values.phone} onChange={handleChange} />
                                            <span>{errors.phone && 'Please enter number for phone.'}</span>
                                        </div>
                                        <div className="form-group col-sm-6">
                                            <label >Email Address</label>
                                            <input className="form-control" type="text" name="email" ref={register({ required: true, pattern: /^\S+@\S+$/i })} 
                                                value={values.email} onChange={handleChange} />
                                            <span>{errors.email && 'Please enter proper email address .'}</span>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-sm-12">
                                            <label className="field-label">Country</label>
                                            <select className="form-control" >
                                                <option name="country" value="India" onChange={handleChange}>India</option>
                                                <option name="country" value="South Africa" onChange={handleChange}>South Africa</option>
                                                <option name="country" value="United State" onChange={handleChange}>United State</option>
                                                <option name="country" value="Australia" onChange={handleChange}>Australia</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-sm-12">
                                            <label className="field-label">Address</label>
                                            <input className="form-control" type="text" name="address" ref={register({ required: true, min: 20, max: 120 })} 
                                                placeholder="Street address" value={values.address} onChange={handleChange} />
                                            <span>{errors.address && 'Please right your address .'}</span>
                                        </div>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" id="gridCheck" type="checkbox" />
                                        <label className="form-check-label" >Check me out</label>
                                    </div>
                                    <button type="submit" className="btn btn-primary mt-2 pull-right">Place Order</button>
                                </form>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <div className="checkout-details">
                                    <div className="order-box">
                                        <div className="title-box">
                                            <div className="checkbox-title">
                                                <h4>Course </h4><span>Total</span>
                                            </div>
                                        </div>
                                        <ul className="qty">
                                            <li>{courseName} <span>₹ {coursePrice}</span></li>                                            
                                        </ul>
                                        <ul className="sub-total">
                                            <li>Subtotal <span className="count">₹{coursePrice}</span></li>
                                            <li>Discount <span className="count">₹0</span></li>                                            
                                        </ul>
                                        <ul className="sub-total total">
                                            <li>Total <span className="count">₹ {coursePrice}</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Checkout;
