//import React, {useReducer} from 'react';
import React, { useReducer, Fragment, useState, useEffect } from 'react';
import Progress from './components/Progress';
import Question from './components/Question';
import Answers from './components/Answers';
import QuizContext from './context/QuizContext';

import {
    SET_ANSWERS,
    SET_CURRENT_QUESTION,
    SET_CURRENT_ANSWER,
    SET_ERROR,
    SET_SHOW_RESULTS,
    RESET_QUIZ,
} from './reducers/types.js';
import quizReducer from './reducers/QuizReducer';

//import './App.css';

import { getAllAssesments } from '../../actions/asyncActions';

//export default function Assesments () {
const Assesments = (props) => {
    const location = props.location
    const secId = location.state ? location.state.id : 1;

    const [allAssesments, setAssesments] = useState([]);  
    useEffect( () => {
        getAllAssesments()
         .then(resp => {
          setAssesments(resp.data);
         })
    }, []);
    console.log("assesments", allAssesments);

    const questions = allAssesments.filter(s => s.section_id === secId);


    // const questions2= [
    //     {
    //         id: 1,
    //         question: 'Which statement about Hooks is not true?',
    //         answer_a:
    //             'Hooks are 100% backwards-compatible and can be used side by side with classes',
    //         answer_b: 'Hooks are still in beta and not available yet',
    //         answer_c:
    //             "Hooks are completely opt-in, there's no need to rewrite existing code",
    //         answer_d: 'All of the above',
    //         correct_answer: 'b',
    //     },
    //     {
    //         id: 2,
    //         question: 'Which one is not a Hook?',
    //         answer_a: 'useState()',
    //         answer_b: 'useConst()',
    //         answer_c: 'useReducer()',
    //         answer_d: 'All of the above',
    //         correct_answer: 'b',
    //     },
    //     {
    //         id: 3,
    //         question: 'What Hook should be used for data fetching?',
    //         answer_a: 'useDataFetching()',
    //         answer_b: 'useApi()',
    //         answer_c: 'useEffect()',
    //         answer_d: 'useRequest()',
    //         correct_answer: 'c',
    //     },
    // ];

    const initialState = {
        questions,
        currentQuestion: 0,
        currentAnswer: '',
        answers: [],
        showResults: false,
        error: '',
    };

    const [state, dispatch] = useReducer(quizReducer, initialState);
    const {currentQuestion, currentAnswer, answers, showResults, error} = state;

    const question = questions[currentQuestion];

    const renderError = () => {
        if (!error) {
            return;
        }

        return <div className="error">{error}</div>;
    };

    const renderResultMark = (question, answer) => {
        if (question.correct_answer === answer.answer) {
            return <span className="correct">Correct</span>;
        }

        return <span className="failed">Failed</span>;
    };

    const renderResultsData = () => {
        return answers.map(answer => {
            const question = questions.find( s => s.id === answer.questionId );

            return (
                <div key={question.id}>
                    {question.question} - {renderResultMark(question, answer)}
                </div>
            );
        });
    };

    const restart = () => {
        dispatch({type: RESET_QUIZ});
    };

    const next = () => {
        const answer = {questionId: question.id, answer: currentAnswer};

        if (!currentAnswer) {
            dispatch({type: SET_ERROR, error: 'Please select an option'});
            return;
        }

        answers.push(answer);
        dispatch({type: SET_ANSWERS, answers});
        dispatch({type: SET_CURRENT_ANSWER, currentAnswer: ''});

        if (currentQuestion + 1 < questions.length) {
            dispatch({
                type: SET_CURRENT_QUESTION,
                currentQuestion: currentQuestion + 1,
            });
            return;
        }

        dispatch({type: SET_SHOW_RESULTS, showResults: true});
    };

    const goBack = () => {
        props.history.push(`${process.env.PUBLIC_URL}/courses/course_models`);
     }

    if (showResults) {
        return (
            <div className="container results">
                <h2>Results</h2>
                <ul>{renderResultsData()}</ul>
                <button className="btn btn-info" style={{marginTop:'15px'}} onClick={restart}>
                    Restart
                </button>
            </div>
        );
    } else {
        return (
            <QuizContext.Provider value={{state, dispatch}}>
            <button className="btn btn-square btn-outline-info btn-sm" onClick={goBack}>Back</button>
                <div className="container"
                    style={{
                        backgroundColor: '#282c34',
                        minHeight: '100vh',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '23px',
                        color: 'white',
                        textAlign: 'center',
                    }}>
                    <Progress
                        total={questions.length}
                        current={currentQuestion + 1}
                    />
                    <Question questions={questions}/>
                    {renderError()}
                    <Answers questions={questions}/>
                    <button className="btn btn-info" style={{marginTop:'15px'}} onClick={next}>
                        Confirm and Continue
                    </button>
                </div>
            </QuizContext.Provider>
        );
    }
}

export default Assesments;